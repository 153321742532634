export const enum PersistKey {
  CURRENT_TENANT_ID = "current_tenant",
}

export const getPersistentItem = (key: PersistKey) => {
  return localStorage.getItem(key);
};

export const setPersistentItem = (key: PersistKey, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error("Failed to store value to persistent storage");
  }
};

export const removePersistentItem = (key: string) => {
  localStorage.removeItem(key);
};
