import { useCliftContext } from "../../hooks/useCliftContext";
import { useTranslation } from "react-i18next";
import "./Home.css";

const Home = () => {
  const { cliftState, currentUser } = useCliftContext();
  const { t } = useTranslation();

  return (
    <>
      <div id="home">
        <div className="home-content">
          {t("welcome_to_connected_lift_console")}
          <br />
          <div>
            {!currentUser.clientPrincipal ? (
              <a href="/.auth/login/aad">{t("log_in", { ns: "profile" })}</a>
            ) : (
              cliftState.tenants?.length === 0 && t("no_tenants_contact_admin")
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
