import { User } from "../models/user";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";

export const getTenantUsers = (tenantId: number): Promise<User[]> =>
  fetch("/api/users?tenant=" + tenantId, {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const createUser = (tenantId: number, name: string, email: string) =>
  fetch("/api/users", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      tenant: { id: tenantId },
      name,
      email,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
