import "./Dropdown.css";

export interface Option {
  label: string;
  id: number;
}

export interface DropdownProps {
  currentId: number | undefined;
  noCurrentText: string;
  noOptionsText: string;
  disabled?: boolean;
  items: Option[];
  onSelect: (id: number) => void;
}

export const Dropdown = ({
  currentId,
  noCurrentText,
  noOptionsText,
  disabled = false,
  items,
  onSelect,
}: DropdownProps) => {
  const getCurrent = () => {
    if (typeof currentId !== "undefined") {
      return items.find((i) => i.id === currentId);
    }
    return;
  };

  return (
    <div className="dropdown">
      <button className="dropdown-button" disabled={disabled}>
        {getCurrent()?.label ?? noCurrentText}
      </button>
      {!disabled && (
        <div className="dropdown-content">
          {items.length ? (
            items.map((item, index) => (
              <button
                type="button"
                key={index}
                onClick={() => onSelect(item.id)}
                disabled={getCurrent()?.id === item.id}
              >
                {item.label ?? noCurrentText}
              </button>
            ))
          ) : (
            <div className="dropdown-no-content">{noOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
};
