import { Context } from "react";
import React from "react";
import { ClientPrincipal } from "../../models/client-principal";
import {
  CliftActions as actions,
  CliftContextState,
} from "./clift-action-types";

export const CliftActionContext = React.createContext<CliftContext | undefined>(
  undefined
) as Context<CliftContext>;

export const enum Panels {
  Alerts = 1,
  Settings = 2,
  User = 4,
}

export type CliftContext = {
  currentUser: ClientPrincipal;
  createTenantPanelVisible: boolean;
  setCreateTenantPanelVisible: (value: boolean) => void;
  hasTenantRole: (tenantIds: (string | undefined)[]) => boolean | undefined;
  cliftState: CliftContextState;
  dispatchCliftState: React.Dispatch<actions>;
};
