import { useEffect, useRef, useState } from "react";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import {
  TenantResponseFull,
  TenantAdmin,
  TenantFormValues,
  TenantType,
} from "../../models/tenant";
import { TenantFormAdminsPage } from "./TenantFormAdminsPage";
import { TenantFormDetailsPage } from "./TenantFormDetailsPage";
import { TenantFormContactsPage } from "./TenantFormContactsPage";
import { Contact, ContactType } from "../../models/contact";
import "./TenantForm.css";

export const enum TenantFormPages {
  TenantDetailsPage,
  TenantAdminPage,
  TenantContactPage,
}

const TENANT_DEFAULT_VALUES = {
  name: null,
  address: null,
  businessId: "",
  email: null,
  phoneNr: null,
  region: null,
  tenantType: TenantType.COUNTRY,
  parentTenant: null,
  contacts: [],
  admins: [{ email: "" }],
};

const DEFAULT_EMERGENCY_CONTACT = {
  displayName: "",
  phoneNr: "112",
  contactType: ContactType.EMERGENCY,
  firstName: "",
  lastName: "",
};

const DEFAULT_SUPPORT_CONTACT = {
  displayName: "Support",
  phoneNr: "",
  contactType: ContactType.SUPPORT,
  firstName: "",
  lastName: "",
};

export interface TenantFormProps {
  onSubmit: (
    tenant: Omit<TenantResponseFull, "id" | "tenant">,
    admins: TenantAdmin[],
    contacts: Contact[]
  ) => Promise<void>;
  onClose: () => void;
  onSwitchPage: (pageIndex: number) => void;
  pageIndex: number;
  initialValues?: TenantFormValues;
  editingTenant?: TenantResponseFull | undefined;
  showSaveOnAllPages?: boolean;
}

export const TenantForm = ({
  onSubmit,
  onClose,
  onSwitchPage,
  pageIndex,
  initialValues = TENANT_DEFAULT_VALUES,
  editingTenant,
  showSaveOnAllPages = false,
}: TenantFormProps) => {
  const [tenant, setTenant] = useState(initialValues);
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const [admins, setAdmins] = useState<string[]>([""]);
  const [supportContacts, setSupportNumbers] = useState<Contact[]>([
    DEFAULT_SUPPORT_CONTACT,
  ]);
  const [emergencyContact, setEmergencyContact] = useState<Contact>(
    DEFAULT_EMERGENCY_CONTACT
  );

  const onHandleFieldChanged = (name: string, value: string | number) => {
    setChanged(true);
    setTenant({
      ...tenant,
      [name]: value,
    });
  };

  const onHandleAdminChanged = (index: number, value: string) => {
    setChanged(true);
    const newValue = admins.map((val, i) => {
      if (i === index) return value as string;
      return val;
    });
    setAdmins(newValue);
  };

  const handleDeleteAdmin = (index: number) => {
    setChanged(true);
    const newList = admins.filter((_, i) => {
      if (i === index) return false;
      return true;
    });
    setAdmins(newList);
  };

  const handleAddAdmin = () => {
    setAdmins(admins.concat(""));
  };

  const handleAddNewContact = () => {
    setChanged(true);
    setSupportNumbers(supportContacts.concat(DEFAULT_SUPPORT_CONTACT));
  };

  const handleDeleteContact = (index: number) => {
    setChanged(true);
    const newList = supportContacts.filter((_, i) => {
      if (i === index) return false;
      return true;
    });
    setSupportNumbers(newList);
  };

  const onHandleContactChanged = (
    name: string,
    index: number,
    value: string
  ) => {
    setChanged(true);
    const newValue = supportContacts.map((val, i) => {
      if (i === index) {
        return {
          ...val,
          [name]: value,
        };
      }
      return val;
    });
    setSupportNumbers(newValue);
  };

  const onHandleEmergencyNumberChanged = (value: string) => {
    setChanged(true);
    setEmergencyContact({
      ...emergencyContact,
      phoneNr: value,
    });
  };

  const onCancel = () => {
    if (hasChanged) {
      setCanceled(true);
    } else {
      onClose();
    }
  };

  const onNext = () => {
    if (form.current?.reportValidity()) {
      onSwitchPage(++pageIndex);
    }
  };

  const onBack = () => {
    if (form.current?.reportValidity()) {
      onSwitchPage(--pageIndex);
    }
  };

  useEffect(() => {
    setAdmins(editingTenant?.admins?.map((admin) => admin.email) ?? [""]);

    const contacts = editingTenant?.contacts?.filter(
      (c) => c.contactType === ContactType.SUPPORT
    );

    setSupportNumbers(contacts?.length ? contacts : [DEFAULT_SUPPORT_CONTACT]);
    setEmergencyContact(
      editingTenant?.contacts?.find(
        (c) => c.contactType === ContactType.EMERGENCY
      ) ?? DEFAULT_EMERGENCY_CONTACT
    );
  }, [editingTenant]);

  return (
    <div className="tenant-form">
      <form
        ref={form}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(
            tenant,
            admins.map((email) => ({ email })),
            supportContacts.concat([emergencyContact])
          );
        }}
      >
        {pageIndex === TenantFormPages.TenantDetailsPage && (
          <TenantFormDetailsPage
            tenant={tenant}
            originalTenant={editingTenant}
            showSave={showSaveOnAllPages}
            onFieldChanged={onHandleFieldChanged}
            onCancel={onCancel}
            onNext={onNext}
          />
        )}

        {pageIndex === TenantFormPages.TenantAdminPage && (
          <TenantFormAdminsPage
            tenant={tenant}
            originalTenant={editingTenant}
            showSave={showSaveOnAllPages}
            admins={admins}
            onAdminChanged={onHandleAdminChanged}
            onCancel={onCancel}
            onNext={onNext}
            onBack={onBack}
            onAddAdmin={handleAddAdmin}
            onDeleteAdmin={handleDeleteAdmin}
          />
        )}
        {pageIndex === TenantFormPages.TenantContactPage && (
          <TenantFormContactsPage
            showSave={true}
            supportContacts={supportContacts}
            emergencyContact={emergencyContact}
            onCancel={onCancel}
            onBack={onBack}
            onAddNewContact={handleAddNewContact}
            onDeleteContact={handleDeleteContact}
            onContactChanged={onHandleContactChanged}
            onEmergencyNumberChanged={onHandleEmergencyNumberChanged}
          />
        )}
      </form>

      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
