export const enum ContactType {
  CUSTOMER_CONTACT = "CUSTOMER_CONTACT",
  SUPPORT = "SUPPORT",
  EMERGENCY = "EMERGENCY",
}

export type Contact = {
  id?: number;
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  contactType: ContactType;
};

export type ContactCommonResponse = {
  id: number;
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
  contactType: ContactType;
};

export type DependableContactResponse = {
  priority: number;
  contactId: number;
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNr: string;
};

export type EditContactInfoRequest = {
  speedDialContactId: number;
  emergencyNumberContactId: number;
  supportNumberContactId: number;
  dependableContacts: DependableContactRequest[];
};

export type DependableContactRequest = {
  priority: number;
  contactId: number;
};
