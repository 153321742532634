import { MouseEvent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

type ConfirmDeleteProps = {
  title: string;
  text: string;
  isOpen: boolean;
  onCancel?: () => void;
  onConfirmed?: () => void;
};

export const ConfirmDelete = ({
  title,
  text,
  isOpen,
  onCancel,
  onConfirmed,
}: ConfirmDeleteProps) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isOpen]);

  const handleCloseModal = () => {
    onCancel?.();
  };

  const handleConfirm = async (e: MouseEvent) => {
    e.preventDefault();
    onConfirmed?.();
  };

  return (
    <dialog ref={modalRef} onClose={handleCloseModal}>
      <div className="confirm-dialog-content">
        <h1>{title}</h1>
        <p>{text}</p>
        <div className="modal-btn-div">
          <button
            className="button modal-close-btn"
            type="button"
            onClick={handleCloseModal}
          >
            {t("cancel")}
          </button>
          <button
            type="button"
            className="button modal-confirm-btn"
            onClick={(e) => {
              handleConfirm(e);
            }}
          >
            {t("confirm")}
          </button>
        </div>
      </div>
    </dialog>
  );
};
