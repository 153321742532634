import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { fetchCustomer } from "../../services/customers-api";
import { ContextMenu } from "../../components/ContextMenu";
import { ReactComponent as EditIcon } from "../../assets/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";

export const CustomerOptionsMenu = ({
  customerID,
  setSelectedCustomerID,
}: {
  customerID: number;
  setSelectedCustomerID(customerID: number): void;
}) => {
  const { cliftState, dispatchCliftState, hasTenantRole } = useCliftContext();
  const { t } = useTranslation();

  const handleEditCustomer = async () => {
    return fetchCustomer(customerID).then((res) => {
      dispatchCliftState({
        type: CliftReducerAction.EditCustomer,
        customer: res,
        customerID,
      });
    });
  };

  return (
    <ContextMenu
      items={[
        {
          id: "edit",
          title: t("edit"),
          hidden:
            customerID === null ||
            !hasTenantRole([cliftState.currentTenant?.id.toString()]),
          onClicked: handleEditCustomer,
          icon: <EditIcon />,
        },
        {
          id: "delete",
          title: t("delete", { ns: "customer" }),
          hidden:
            customerID === null ||
            !hasTenantRole([cliftState.currentTenant?.id.toString()]),
          onClicked: () => setSelectedCustomerID(customerID),
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
