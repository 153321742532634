import { MouseEvent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Close.svg";
import "./AddCustomerLiftsConfirm.css";
import { CustomerDeviceFormValues } from "../../models/customer";

type AddCustomerLiftsConfirmProps = {
  isOpen: boolean;
  devices: CustomerDeviceFormValues[];
  onCancel: () => void;
  onConfirm: () => void;
};

export const AddCustomerLiftsConfirm = ({
  isOpen,
  devices,
  onCancel,
  onConfirm,
}: AddCustomerLiftsConfirmProps) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  const handleConfirm = async (e: MouseEvent) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <dialog ref={modalRef} onCancel={onCancel}>
      <div className="add-customer-lift-confirm-dialog-content">
        <div className="add-customer-lift-confirm-dialog-close-icon-div">
          <CloseIcon className="dialog-close-icon" onClick={onCancel} />
        </div>
        <h1 className="add-customer-lift-confirm-dialog-header">
          {t("confirm_add_device_title", { ns: "customer" })}
        </h1>
        <p>{t("confirm_add_device_info", { ns: "customer" })}</p>
        <div className="add-customer-lift-confirm-serial-list">
          {devices.map((device, i) => (
            <div key={i}>
              <b>{device.serialNumber}</b>{" "}
              {device.nickname ? " - " + device.nickname : ""}
            </div>
          ))}
        </div>
        <div className="modal-btn-div">
          <button
            type="button"
            className="button modal-close-btn"
            onClick={onCancel}
          >
            {t("edit_serial_number_btn", { ns: "customer" })}
          </button>
          <button
            className="button modal-confirm-dialog-btn"
            onClick={(e) => {
              handleConfirm(e);
            }}
          >
            {t("confirm", { ns: "translation" })}
          </button>
        </div>
      </div>
    </dialog>
  );
};
