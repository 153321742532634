import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { TenantListItem } from "../../models/tenant-list-item";
import { ContextMenu } from "../../components/ContextMenu";
import { ReactComponent as EditIcon } from "../../assets/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";

export const TenantOptionsMenu = ({
  tenant,
  setSelectedTenant,
}: {
  tenant: TenantListItem;
  setSelectedTenant(tenant: TenantListItem): void;
}) => {
  const { dispatchCliftState, hasTenantRole } = useCliftContext();
  const { t } = useTranslation();

  const handleEditTenant = (_: string) => {
    dispatchCliftState({
      type: CliftReducerAction.EditTenant,
      tenantID: tenant.id,
    });
  };

  return (
    <ContextMenu
      items={[
        {
          id: "edit",
          title: t("edit"),
          hidden: !hasTenantRole([
            tenant.id.toString(),
            tenant.parentTenantId?.toString(),
          ]),
          onClicked: handleEditTenant,
          icon: <EditIcon />,
        },
        {
          id: "delete",
          title: t("delete_tenant", { ns: "tenant" }),
          hidden: !hasTenantRole([
            tenant.id.toString(),
            tenant.parentTenantId?.toString(),
          ]),
          onClicked: () => setSelectedTenant(tenant),
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
