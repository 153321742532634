import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TenantOptionsMenu } from "./TenantOptionsMenu";
import List from "../../components/List";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { TenantListItem } from "../../models/tenant-list-item";

export interface TenantListProps {
  parentTenant: number;
  tenants: TenantListItem[];
  setSelectedTenant(tenant: TenantListItem): void;
}

export const TenantList = ({
  parentTenant,
  setSelectedTenant,
  tenants,
}: TenantListProps) => {
  const { t, i18n } = useTranslation();
  const [loading] = useState(false);
  const columnHelper = createColumnHelper<TenantListItem>();

  const columns: ColumnDef<TenantListItem, string>[] = useMemo(
    () => [
      columnHelper.accessor((row) => row.id + "", {
        id: "id",
        header: () => t("tenant_id", { ns: "tenant" }),
      }),
      columnHelper.accessor("name", {
        header: () => t("name", { ns: "translation" }),
        cell: (info) => (
          <Link to={info.row.original.id.toString()}>{info.getValue()}</Link>
        ),
      }),
      columnHelper.accessor("address", {
        header: () => t("address", { ns: "contact" }),
        cell: (info) => (
          <Link to={info.row.original.id.toString()}>{info.getValue()}</Link>
        ),
      }),
      columnHelper.display({
        id: "menu",
        header: "",
        cell: (info) => {
          const tenant = tenants.find((t) => t.id === info.row.original.id);
          return tenant ? (
            <TenantOptionsMenu
              tenant={tenant}
              setSelectedTenant={setSelectedTenant}
            />
          ) : undefined;
        },
      }),
    ],
    [i18n.language]
  );

  if (!loading) {
    return (
      <List<TenantListItem>
        columns={columns}
        data={tenants.filter(
          (tenant) => tenant.parentTenantId === parentTenant
        )}
      />
    );
  } else {
    return <progress></progress>;
  }
};
