import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toast";
import { AlertsPanel } from "../components/AlertsPanel";
import { UserPanel } from "../components/UserPanel";
import Navbar from "../components/Navbar";
import { Topmenu } from "../components/Topmenu";
import { useCliftContext } from "../hooks/useCliftContext";
import { CliftReducerAction } from "../context/clift-context/clift-action-types";

export const Layout = () => {
  const { currentUser, cliftState, dispatchCliftState } = useCliftContext();

  useEffect(() => {
    if (currentUser.clientPrincipal?.userRoles?.includes("authenticated")) {
      dispatchCliftState({
        type: CliftReducerAction.FetchTenants,
      });
    }
  }, [cliftState.currentTenant, currentUser.clientPrincipal?.userRoles]);

  return (
    <>
      <div
        id="app-container"
        className={cliftState.sidepanelContent ? "sidepanelopen" : ""}
        //https://stackoverflow.com/a/77153892
        {...{ inert: cliftState.sidepanelContent ? "" : undefined }}
      >
        <Navbar />
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Topmenu />
          <AlertsPanel />
          <UserPanel />
          <div className="app-container-content">
            <Outlet />
            <ToastContainer position="bottom-center" delay={5000} />
          </div>
        </div>
      </div>
      {cliftState.sidepanelContent ? cliftState.sidepanelContent : null}
    </>
  );
};
