import { useTranslation } from "react-i18next";
import Card, {
  CardDetail,
  CardDetailTitled,
  CardGroup,
} from "../../../components/Card";
import { ContactInfo } from "../../../models/device";
import { DependableContactResponse } from "../../../models/contact";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { ReactComponent as PhoneIcon } from "../../../assets/Phone.svg";
import "./LiftDetails.css";

export interface ContactRowProps {
  contact: { displayName: string; phoneNr: string };
}

export const ContactRow = ({ contact }: ContactRowProps) => {
  return (
    <CardDetail
      icon={<PhoneIcon width={"20px"} height={"20px"} />}
      value={
        <div className="contact-row">
          <div className="col">{contact.displayName}</div>
          <div className="col card-detail-value">{contact.phoneNr}</div>
        </div>
      }
    />
  );
};

export interface ContactInfoCardProps {
  deviceId: number;
  contactInfo: ContactInfo;
}

export const ContactInfoCard = ({
  deviceId,
  contactInfo,
}: ContactInfoCardProps) => {
  const { dispatchCliftState } = useCliftContext();
  const handleEdit = () => {
    dispatchCliftState({
      type: CliftReducerAction.EditLiftPhoneNumbers,
      deviceId: deviceId,
      contactInfo: contactInfo,
    });
  };
  const { t } = useTranslation();
  return (
    <Card title={t("calls", { ns: "lift" })} onEdit={handleEdit}>
      <CardDetailTitled
        key="speed_dial"
        title={t("speed_dial", { ns: "lift" })}
        value={contactInfo?.speedDialNumber?.phoneNr}
      />
      <CardDetailTitled
        key="emergency"
        title={t("emergency", { ns: "lift" })}
        value={contactInfo?.emergencyNumber?.phoneNr}
      />
      <CardDetailTitled
        key="support-number"
        title={t("support_number", { ns: "lift" })}
        value={contactInfo?.supportNumber?.phoneNr}
      />
      <CardGroup
        title={t("dependable_contacts_title", { ns: "lift" })}
        subtitle={t("dependable_contacts_subtitle", { ns: "lift" })}
      >
        {contactInfo.dependableContacts
          .sort((a: DependableContactResponse, b: DependableContactResponse) =>
            a.priority > b.priority ? 1 : -1
          )
          .map((dp: DependableContactResponse) => (
            <ContactRow key={dp.contactId + "_" + dp.priority} contact={dp} />
          ))}
      </CardGroup>
    </Card>
  );
};
