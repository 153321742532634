import { MouseEvent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./ConfirmDialog.css";
import { ReactComponent as CloseIcon } from "../assets/Close.svg";

type ConfirmProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmDialog = ({
  isOpen,
  onCancel,
  onConfirm,
}: ConfirmProps) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  const handleConfirm = async (e: MouseEvent) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <dialog ref={modalRef} onCancel={onCancel}>
      <div className="confirm-dialog-content">
        <div className="dialog-close-icon-div">
          <CloseIcon className="dialog-close-icon" onClick={onCancel} />
        </div>
        <h1 className="confirm-dialog-header">
          {t("leave_confirmation_title", { ns: "dialogs" })}
        </h1>
        <p>{t("leave_confirmation_info", { ns: "dialogs" })}</p>
        <div className="modal-btn-div">
          <button className="button modal-close-btn" onClick={onCancel}>
            {t("keep_editing", { ns: "dialogs" })}
          </button>
          <button
            className="button modal-confirm-dialog-btn"
            onClick={(e) => {
              handleConfirm(e);
            }}
          >
            {t("leave", { ns: "dialogs" })}
          </button>
        </div>
      </div>
    </dialog>
  );
};
