import { useTranslation } from "react-i18next";
import Card, { CardDetail, CardDetailTitled } from "../../../components/Card";
import { DeviceInfo } from "../../../models/device";
import { useEffect, useState } from "react";
import { fetchDeviceInfo } from "../../../services/devices-api";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { getErrorCode } from "../../../services/clift-api-errors";

export interface DeviceTechInfoCardProps {
  deviceId: number | undefined;
}

export const DeviceTechInfoCard = ({ deviceId }: DeviceTechInfoCardProps) => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (deviceId !== undefined && cliftState.currentTenant) {
      fetchDeviceInfo(cliftState.currentTenant?.id || 0, deviceId)
        .then((devInfo) => {
          setFetchError(false);
          setLoading(false);
          setDeviceInfo(devInfo);
        })
        .catch((err) => {
          setLoading(false);
          setFetchError(true);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("lift_info_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [deviceId, cliftState.currentTenant]);

  return (
    <Card title={t("device_tech_info", { ns: "lift" })}>
      {loading && <progress></progress>}
      {fetchError && !loading && (
        <CardDetail
          value={t("lift_info_fetch_failed", { ns: "lift" })}
        ></CardDetail>
      )}
      {deviceInfo && (
        <>
          <CardDetailTitled
            title={t("manufacturer", { ns: "lift" })}
            value={deviceInfo!.manufacturer}
          />
          <CardDetailTitled
            title={t("model", { ns: "lift" })}
            value={deviceInfo!.model}
          />
          <CardDetailTitled
            title={t("sw_version", { ns: "lift" })}
            value={deviceInfo!.swVersion}
          />
          <CardDetailTitled
            title={t("os_name", { ns: "lift" })}
            value={deviceInfo!.osName}
          />
          <CardDetailTitled
            title={t("processor_architecture", { ns: "lift" })}
            value={deviceInfo!.processorArchitecture}
          />
          <CardDetailTitled
            title={t("processor_manufacturer", { ns: "lift" })}
            value={deviceInfo!.processorManufacturer}
          />
          <CardDetailTitled
            title={t("total_storage", { ns: "lift" })}
            value={String(deviceInfo!.totalStorage)}
          />
          <CardDetailTitled
            title={t("total_memory", { ns: "lift" })}
            value={String(deviceInfo!.totalMemory)}
          />
        </>
      )}
    </Card>
  );
};
