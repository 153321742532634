import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useEffect, useState } from "react";
import { toast } from "react-toast";
import Page from "../../components/Page";
// TODO: Add this on later Jira ticket
//import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { LiftList } from "./LiftList";
import { ConfirmDelete } from "../../components/ConfirmDelete";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import {
  CustomerDevice,
  removeDeviceFromCustomer,
} from "../../services/customers-api";

export const LiftsPage = () => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [updateView, setUpdateView] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<CustomerDevice>();

  useEffect(() => {
    if (cliftState.currentTenant) {
      dispatchCliftState({
        type: CliftReducerAction.FetchDevices,
      });
    }
  }, [cliftState.currentTenant, updateView]);

  if (!cliftState.currentTenant) {
    return <></>;
  }

  if (!cliftState.devices) {
    return <progress></progress>;
  }

  const handleRemoveDeviceFromCustomer = async () => {
    if (selectedDevice === undefined) return;

    await removeDeviceFromCustomer(selectedDevice).catch((err) => {
      dispatchCliftState({
        type: CliftReducerAction.AddAlert,
        alert: t("delete_device_http_fail", {
          ns: "alerts",
          code: getErrorCode(err),
          reason: getLocalizedErrorReason(err),
        }),
      });
      toast.error(
        t("error") +
          t("delete_device_toast_error", {
            ns: "alerts",
            reason: getLocalizedErrorReason(err),
          })
      );
    });

    setSelectedDevice(undefined);
    setUpdateView(!updateView);
  };

  return (
    <Page
      title={t("lifts", { ns: "lift" })}
      subtitle={t("lifts_subtitle", { ns: "lift" })}
      // TODO: Add lift button implementation in later ticket
      /*button={
        <button
          className="button"
          type="button"
          onClick={() => {
            // Add new Lift
            // Implement this on ticket: CLIFT-282
          }}
        >
          <PlusIcon />

          {t("add_new_lift", { ns: "lift" })}
        </button>
      }*/
    >
      <>
        <LiftList
          devices={cliftState.devices}
          setSelectedDevice={setSelectedDevice}
        />

        {selectedDevice !== undefined && (
          <ConfirmDelete
            title={t("remove_lift_question", { ns: "dialogs" })}
            text={t("confirm_lift_remove_question", {
              ns: "dialogs",
              device: selectedDevice.deviceID,
            })}
            isOpen={selectedDevice !== undefined}
            onCancel={() => setSelectedDevice(undefined)}
            onConfirmed={handleRemoveDeviceFromCustomer}
          />
        )}
      </>
    </Page>
  );
};
