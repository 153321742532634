import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../translations/en.json" with { type: "json" };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
    },
    lng: "en",
    defaultNS: "translation",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
