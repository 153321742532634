import { useTranslation } from "react-i18next";
import { Panels } from "../context/clift-context/clift-context";
import { useCliftContext } from "../hooks/useCliftContext";
import { CliftReducerAction } from "../context/clift-context/clift-action-types";
import "./Topmenu.css";
import { Dropdown } from "./Dropdown";
import { ReactComponent as SettingsIcon } from "../assets/Cog.svg";
import { ReactComponent as ProfileIcon } from "../assets/Profile.svg";
import { ReactComponent as NotificationIcon } from "../assets/Notification.svg";
import { useNavigate } from "react-router-dom";

export const Topmenu = () => {
  const { t } = useTranslation();
  const { cliftState, dispatchCliftState } = useCliftContext();
  const navigate = useNavigate();

  return (
    <div className="topmenu">
      <menu>
        {cliftState.tenants && cliftState.tenants.length > 0 && (
          <Dropdown
            currentId={cliftState.currentTenant?.id}
            items={
              cliftState.tenants.map((t) => ({
                label: t.name ?? "",
                id: t.id,
              })) ?? []
            }
            disabled={cliftState.tenants.length < 2}
            noCurrentText={t("select_tenant")}
            noOptionsText={t("no_tenants")}
            onSelect={(tenantId) => {
              const tenant = cliftState.tenants?.find((t) => t.id === tenantId);
              if (tenant) {
                dispatchCliftState({
                  type: CliftReducerAction.SetCurrentTenant,
                  tenant,
                });
                // Work-a-round: cannot navigate from context provider as it outside of router.
                setTimeout(() => {
                  navigate("/", { replace: true });
                }, 100);
              }
            }}
          />
        )}
        <li
          onClick={() => {
            dispatchCliftState({
              type: CliftReducerAction.AlertPanelVisible,
              visible: !(cliftState.visiblePanels & Panels.Alerts),
            });
          }}
        >
          <NotificationIcon className="topmenu-icon" />
        </li>
        <li>
          <SettingsIcon className="topmenu-icon" />
        </li>
        <li
          onClick={() => {
            dispatchCliftState({
              type: CliftReducerAction.UserPanelVisible,
              visible: !(cliftState.visiblePanels & Panels.User),
            });
          }}
        >
          <ProfileIcon className="topmenu-icon" />
        </li>
      </menu>
    </div>
  );
};
