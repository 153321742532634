import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { CustomerDevice } from "../../services/customers-api";
import { ContextMenu } from "../../components/ContextMenu";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";

export const LiftOptionsMenu = ({
  customerID,
  deviceID,
  setSelectedDevice,
}: {
  customerID: number | null;
  deviceID: number;
  setSelectedDevice(selectedDevice: CustomerDevice): void;
}) => {
  const { cliftState, hasTenantRole } = useCliftContext();
  const { t } = useTranslation();

  const handleRemoveDevice = async () => {
    if (customerID !== null && customerID !== undefined) {
      setSelectedDevice({ customerID, deviceID });
    }
  };

  return (
    <ContextMenu
      items={[
        {
          id: "delete",
          title: t("remove_lift_from_customer", { ns: "lift" }),
          disabled: customerID === null || customerID === undefined,
          hidden: !hasTenantRole([cliftState.currentTenant?.id.toString()]),
          onClicked: handleRemoveDevice,
          icon: <DeleteIcon />,
        },
      ]}
    />
  );
};
