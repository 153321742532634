import { useTranslation } from "react-i18next";
import Card, { CardDetailTitled } from "../../../components/Card";
import { Device } from "../../../models/device";

export interface DeviceInfoCardProps {
  device: Device;
}

export const DeviceInfoCard = ({ device }: DeviceInfoCardProps) => {
  const { t } = useTranslation();
  return (
    <Card title={device.model}>
      {device.activated && (
        <div className="active-status">
          <span>{t("active", { ns: "lift" })}</span>
        </div>
      )}
      <CardDetailTitled
        title={t("serial_number", { ns: "lift" })}
        value={device.serialNumber.toString()}
      />
      <CardDetailTitled
        title={t("nickname", { ns: "lift" })}
        value={device.contactInfo?.nickname}
      />
    </Card>
  );
};
