import {
  CommissioningDataDownloadSettings,
  DeviceUILanguage,
  LogsDownloadSettings,
  OtaDownloadSettings,
  SubscriptionState,
} from "models/device";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";

export type EditDeviceSettingsRequest = {
  subscriptionState: SubscriptionState;
  deviceUiLanguage: DeviceUILanguage;

  otaDownloadSettings: OtaDownloadSettings;
  logsDownloadSettings: LogsDownloadSettings;
  commissioningDataDownloadSettings: CommissioningDataDownloadSettings;

  factoryResetCommand: string;
  downloadLogsCommand: string;
  downloadCommissioningDataCommand: string;
};

export const editDeviceSettings = (
  tenantId: number,
  deviceId: number,
  request: EditDeviceSettingsRequest
): Promise<void> => {
  return fetch(`/api/tenant/${tenantId}/device/${deviceId}/settings`, {
    method: "PUT",
    body: JSON.stringify({
      ...request,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
};
