import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useTranslation } from "react-i18next";
import SideModal from "../../components/SideModal";
import {
  CustomerDeviceFormValues,
  CustomerFullResponse,
} from "../../models/customer";
import { AddCustomerLiftsForm } from "./AddCustomerLiftsForm";
import { addDeviceToCustomer } from "../../services/customers-api";
import { getErrorCode } from "../../services/clift-api-errors";
import { useState } from "react";

export interface AddCustomerLiftsProps {
  editingCustomer: CustomerFullResponse;
}

export const AddCustomerLifts = ({
  editingCustomer,
}: AddCustomerLiftsProps) => {
  const { dispatchCliftState } = useCliftContext();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
    setError(undefined);
  };

  const onAddLifts = (
    customer: CustomerFullResponse,
    devices: CustomerDeviceFormValues[]
  ) => {
    addDeviceToCustomer(customer.id, devices)
      .then(() => {
        closeSidePanel();
      })
      .catch((err) => {
        setError(getErrorCode(err) as string);
      });
  };

  return (
    <SideModal
      title={t("add_lifts_title", { ns: "customer" })}
      onClose={closeSidePanel}
    >
      <AddCustomerLiftsForm
        editingCustomer={editingCustomer}
        error={error}
        initialDevices={editingCustomer.devices as CustomerDeviceFormValues[]}
        onSubmit={onAddLifts}
        onClose={closeSidePanel}
      />
    </SideModal>
  );
};
