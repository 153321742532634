import { useState } from "react";
import "./EntityPicker.css";

export interface Option {
  label: string;
  id: number;
}

export interface PickerProps {
  currentId: number | undefined;
  noCurrentText: string;
  noOptionsText: string;
  disabled?: boolean;
  title?: string;
  required?: boolean;
  items: Option[];
  onSelect: (id: number) => void;
}

export const EntityPicker = ({
  currentId,
  noCurrentText,
  noOptionsText,
  disabled = false,
  required = false,
  items,
  title,
  onSelect,
}: PickerProps) => {
  const getCurrent = () => {
    if (typeof currentId !== "undefined") {
      return items.find((i) => i.id === currentId);
    }
    return;
  };
  const notSelectedElement = () => {
    if (required) {
      return <span className="required-warning">{noCurrentText}</span>;
    }
    return noCurrentText;
  };
  const [showOptions, setShowOptions] = useState<boolean>(false);
  return (
    <div className="picker">
      {title && <h1>{title}</h1>}
      <button
        className="picker-button"
        disabled={disabled}
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        {getCurrent()?.label ?? notSelectedElement()}
      </button>
      {!disabled && showOptions && (
        <div className="picker-content">
          {items.length ? (
            items.map((item, index) => (
              <button
                type="button"
                key={index}
                onClick={() => {
                  onSelect(item.id);
                  setShowOptions(false);
                }}
              >
                {item.label ?? noCurrentText}
              </button>
            ))
          ) : (
            <div className="picker-no-content">{noOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
};
