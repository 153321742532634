import { useTranslation } from "react-i18next";
import Home from "./home/Home";
import { TenantDetail } from "./tenants/TenantDetail";
import { LiftsPage } from "./lifts/LiftsPage";
import { LiftDetails } from "./lifts/details/LiftDetails";
import { CustomerDetail } from "./customers/CustomerDetail";
import { CustomersPage } from "./customers/CustomersPage";
import { TenantsPage } from "./tenants/TenantsPage";
import { Layout } from "./Layout";

const PageNotFound = () => {
  const { t } = useTranslation();
  return <div>{t("page_not_found")}</div>;
};

export const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/lifts",
        element: <LiftsPage />,
      },
      {
        path: "/lifts/:deviceID",
        element: <LiftDetails />,
      },
      {
        path: "/tenants/:tenantID",
        element: <TenantDetail />,
      },
      {
        path: "/tenants",
        element: <TenantsPage />,
      },
      {
        path: "/customers",
        element: <CustomersPage />,
      },
      {
        path: "/customers/:customerID",
        element: <CustomerDetail />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
];

export default routes;
