import { ReactNode, MouseEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "../hooks/useCliftContext";
import { ReactComponent as BackIcon } from "../assets/Back.svg";
import "./Page.css";

export interface PageProps {
  title: string;
  subtitle?: string;
  button?: ReactNode;
  children?: ReactNode;
}

const Page = ({ title, subtitle, button, children }: PageProps) => {
  const { t } = useTranslation();
  const { cliftState } = useCliftContext();
  const location = useLocation();
  const navigate = useNavigate();

  const setCapitalized = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getLocalizedPath = (path: string) => {
    switch (path) {
      case "tenants":
        return cliftState.currentTenant?.tenantType === "ROOT"
          ? t("markets", { ns: "breadcrumb" })
          : t("tenants", { ns: "breadcrumb" });
      case "customers":
        return t("customers", { ns: "breadcrumb" });
      case "lifts":
        return t("lifts", { ns: "breadcrumb" });
      default:
        return setCapitalized(path);
    }
  };

  const getBreadcrumbs = () => {
    const paths = location.pathname.split("/").filter((x) => x);

    return paths.map((value, i) => {
      const last = i === paths.length - 1;
      const to = `/${paths.slice(0, i + 1).join("/")}`;

      // Validate against CWE-79
      return last || !/^[a-zA-Z0-9_\-/]*$/.test(to) ? (
        <span key={i}>{setCapitalized(title)}</span>
      ) : (
        <span key={i}>
          <Link to={to}>{getLocalizedPath(value)}</Link> &gt;{" "}
        </span>
      );
    });
  };

  const isSubPage = () => {
    return location.pathname.split("/").length > 2;
  };

  const handleBack = (e: MouseEvent) => {
    e.preventDefault();
    navigate("..", { relative: "path", replace: true });
  };

  return (
    <div className="page">
      <div className="page-breadcrumb">{getBreadcrumbs()}</div>
      <header>
        {isSubPage() && (
          <button
            title="back"
            type="button"
            className="page-back"
            onClick={handleBack}
          >
            <BackIcon />
          </button>
        )}
        <div className="page-title-area ">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
        <div className="page-button-area">{button}</div>
      </header>
      {children}
    </div>
  );
};

export default Page;
