import { useState } from "react";
import "./EntityPicker.css";

export interface Option<T> {
  label: string;
  value: T;
}

export interface PickerProps<T> {
  currentValue: T | undefined;
  noCurrentText: string;
  noOptionsText: string;
  disabled?: boolean;
  title?: string;
  items: Option<T>[];
  onSelect: (value: T) => void;
}

export const Picker = <T,>({
  currentValue,
  noCurrentText,
  noOptionsText,
  disabled = false,
  items,
  title,
  onSelect,
}: PickerProps<T>) => {
  const getCurrent = () => {
    if (typeof currentValue !== "undefined") {
      return items.find((i) => i.value === currentValue);
    }
    return;
  };
  const [showOptions, setShowOptions] = useState<boolean>(false);

  return (
    <div className="picker">
      {title && <h1 className="col title">{title}</h1>}
      <button
        className="picker-button"
        type="button"
        disabled={disabled}
        onClick={() => {
          const newShowOptions = !showOptions;
          setShowOptions(newShowOptions);
        }}
      >
        {getCurrent()?.label ?? noCurrentText}
      </button>
      {!disabled && showOptions && (
        <div className="picker-content">
          {items.length ? (
            items.map((item, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  onSelect(item.value);
                  setShowOptions(false);
                }}
              >
                {item.label ?? noCurrentText}
              </button>
            ))
          ) : (
            <div className="picker-no-content">{noOptionsText}</div>
          )}
        </div>
      )}
    </div>
  );
};
