import FormField from "../../components/FormField";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PlusIcon } from "../../assets/Plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Delete.svg";
import { Customer, CustomerFormValues } from "../../models/customer";
import "./CustomerForm.css";
import { Contact, ContactType } from "../../models/contact";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { SideModalButtonBar } from "../../components/SideModalButtonBar";

export const enum CreateCustomerPages {
  CustomerPage,
  CustomerContactsPage,
}

export interface CustomerFormProps {
  onSubmit: (
    customer: Omit<Customer, "id" | "">,
    contacts: Contact[]
  ) => Promise<void>;
  onClose: () => void;
  onSwitchPage: (pageIndex: number) => void;
  pageIndex: number;
  initialValues?: CustomerFormValues;
  editingCustomer?: Customer | undefined;
  showSaveOnFirstPage?: boolean;
}

export const CustomerForm = ({
  onSubmit,
  onClose,
  onSwitchPage,
  pageIndex,
  initialValues = {
    firstName: null,
    lastName: null,
    organization: null,
    vat: null,
    email: null,
    phoneNr: null,
    address: null,
    apartmentNr: null,
    zipCode: null,
    city: null,
    region: null,
    country: null,
    contacts: [],
  },
  editingCustomer,
  showSaveOnFirstPage = false,
}: CustomerFormProps) => {
  const { t } = useTranslation();
  const [canceled, setCanceled] = useState(false);
  const [hasChanged, setChanged] = useState(false);
  const [customer, setCustomer] = useState(initialValues);
  const customerForm = useRef<HTMLFormElement>(null);
  const [contacts, setContacts] = useState<Contact[]>(
    initialValues.contacts || []
  );

  const onHandleFieldChanged = (name: string, value: string | number) => {
    setChanged(true);
    setCustomer({
      ...customer,
      [name]: value,
    });
  };

  const onHandleContactChanged = (
    name: string,
    value: string | number,
    index: number
  ) => {
    setChanged(true);
    const newContacts = contacts.map((contact, i) => {
      if (i === index) {
        return {
          ...contact,
          [name]: value as string,
        };
      }
      return contact;
    });
    setContacts(newContacts);
  };

  return (
    <div className="customer-form">
      <form
        ref={customerForm}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(customer, contacts);
        }}
      >
        {pageIndex === CreateCustomerPages.CustomerPage && (
          <div className="customer-form-customer-page">
            <div className="customer-form-inputs">
              <FormField
                title={t("customer_id", { ns: "customer" })}
                name="customerId"
                disabled
                value={editingCustomer?.id}
                onValueChanged={onHandleFieldChanged}
                hintText={t("customer_id_hint", { ns: "customer" })}
              />

              <h3>{t("personal_information", { ns: "customer" })}</h3>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("first_name", { ns: "customer" })}
                  name="firstName"
                  required
                  value={customer.firstName ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />

                <FormField
                  title={t("last_name", { ns: "customer" })}
                  name="lastName"
                  required
                  value={customer.lastName ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("organization", { ns: "customer" })}
                  name="organization"
                  value={customer.organization ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />

                <FormField
                  className="c-vat formfield"
                  title={t("vat", { ns: "customer" })}
                  name="vat"
                  value={customer.vat ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <h3>{t("contact_information", { ns: "customer" })}</h3>

              <FormField
                title={t("email", { ns: "customer" })}
                name="email"
                type="email"
                value={customer.email ?? ""}
                onValueChanged={onHandleFieldChanged}
                required={true}
              />

              <FormField
                title={t("phone_nr", { ns: "customer" })}
                name="phoneNr"
                type="tel"
                value={customer.phoneNr ?? ""}
                onValueChanged={onHandleFieldChanged}
                required={true}
              />

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("address", { ns: "customer" })}
                  name="address"
                  value={customer.address ?? ""}
                  onValueChanged={onHandleFieldChanged}
                  required={true}
                />

                <FormField
                  className="c-apartment-nr formfield"
                  title={t("apartment_nr", { ns: "customer" })}
                  name="apartmentNr"
                  value={customer.apartmentNr ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("city", { ns: "customer" })}
                  name="city"
                  value={customer.city ?? ""}
                  onValueChanged={onHandleFieldChanged}
                  required={true}
                />

                <FormField
                  className="c-zip-code formfield"
                  title={t("zip_code", { ns: "customer" })}
                  name="zipCode"
                  value={customer.zipCode ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>

              <div className="customer-form-creation-input-row">
                <FormField
                  title={t("region", { ns: "customer" })}
                  name="region"
                  value={customer.region ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />

                <FormField
                  title={t("country", { ns: "customer" })}
                  name="country"
                  value={customer.country ?? ""}
                  onValueChanged={onHandleFieldChanged}
                />
              </div>
            </div>

            <SideModalButtonBar
              onCancel={() => {
                if (hasChanged) {
                  setCanceled(true);
                } else {
                  onClose();
                }
              }}
              onNext={() => {
                if (customerForm.current?.reportValidity()) {
                  onSwitchPage(CreateCustomerPages.CustomerContactsPage);
                }
              }}
              showSave={showSaveOnFirstPage}
            />
          </div>
        )}

        {pageIndex === CreateCustomerPages.CustomerContactsPage && (
          <div className="customer-form-contact-page">
            <div className="customer-form-creation-row">
              <div>
                <h3>{t("customer_contacts", { ns: "customer" })}</h3>
                <h4>{t("customer_contacts_hint", { ns: "customer" })}</h4>
              </div>

              <button
                className="button"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setContacts([
                    {
                      displayName: "",
                      firstName: "",
                      lastName: "",
                      phoneNr: "",
                      contactType: ContactType.CUSTOMER_CONTACT,
                    },
                    ...(contacts || []),
                  ]);
                  customerForm.current?.reset();
                }}
              >
                <PlusIcon />
                {t("add_new_contact", { ns: "customer" })}
              </button>
            </div>
            {(contacts || []).map((contact, i) => (
              <div key={i} className="customer-form-contact-users">
                <FormField
                  title="Display name"
                  name="displayName"
                  value={contact.displayName}
                  required={true}
                  autoComplete="off"
                  onValueChanged={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />
                <FormField
                  title="First name"
                  name="firstName"
                  value={contact.firstName}
                  autoComplete="off"
                  onValueChanged={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />
                <FormField
                  title="Last name"
                  name="lastName"
                  value={contact.lastName}
                  autoComplete="off"
                  onValueChanged={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />
                <FormField
                  title="Phone number"
                  name="phoneNr"
                  value={contact.phoneNr}
                  autoComplete="off"
                  required={true}
                  onValueChanged={(name, value) =>
                    onHandleContactChanged(name, value, i)
                  }
                />
                <button
                  className="delete-contact-button"
                  title={t("delete", { ns: "translation" })}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setContacts(contacts.toSpliced(i, 1));
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
            <div className="customer-form-errors"></div>

            <SideModalButtonBar
              onCancel={() => {
                if (hasChanged) {
                  setCanceled(true);
                } else {
                  onClose();
                }
              }}
              onBack={() => {
                onSwitchPage(CreateCustomerPages.CustomerPage);
              }}
              showSave
            />
          </div>
        )}
      </form>
      <ConfirmDialog
        isOpen={canceled}
        onCancel={() => {
          setCanceled(false);
        }}
        onConfirm={() => {
          onClose();
          setCanceled(false);
        }}
      />
    </div>
  );
};
