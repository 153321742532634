import { Contact } from "./contact";

export type TenantAdmin = { roleId?: number; email: string };

export type TenantResponseFull = {
  address: string | null;
  businessId: string;
  email: string | null;
  id: number;
  name: string | null;
  phoneNr: string | null;
  region: string | null;
  parentTenant: TenantResponseFull | null;
  tenantType: string | null;
  contacts: Contact[] | null;
  admins: TenantAdmin[] | null;
};

export type TenantFormValues = Omit<TenantResponseFull, "id" | "tenant">;

export const enum TenantType {
  ROOT = "ROOT",
  COUNTRY = "COUNTRY",
  DEALER = "DEALER",
}
