import { useCliftContext } from "../../hooks/useCliftContext";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { useTranslation } from "react-i18next";
import { TenantForm } from "./TenantForm";
import { fetchTenant, updateTenant } from "../../services/tenants-api";
import { useEffect, useState } from "react";
import SideModal from "../../components/SideModal";
import {
  TenantResponseFull,
  TenantAdmin,
  TenantFormValues,
  TenantType,
} from "../../models/tenant";
import {
  getErrorCode,
  getLocalizedErrorReason,
} from "../../services/clift-api-errors";
import { Contact } from "../../models/contact";
import { toast } from "react-toast";

export interface EditTenantProps {
  page?: number | undefined;
  tenantID: number;
}

export const EditTenant = ({ page, tenantID }: EditTenantProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [currentPageIndex, setCurrentPageIndex] = useState(page ?? 0);
  const [tenant, setTenant] = useState<TenantResponseFull>();
  const { t } = useTranslation();

  useEffect(() => {
    fetchTenant(tenantID)
      .then((res) => {
        setTenant(res);
      })
      .catch((err) => {
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("tenant_edit_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });

    dispatchCliftState({
      type: CliftReducerAction.FetchTenantUsers,
      tenantID,
    });
  }, [tenantID]);

  if (!tenant) return <div />;

  const closeSidePanel = () => {
    dispatchCliftState({
      type: CliftReducerAction.EditTenant,
      tenantID: undefined,
    });
    dispatchCliftState({
      type: CliftReducerAction.CloseSidePanel,
    });
  };

  const onUpdateTenant = (
    formValues: TenantFormValues,
    admins: TenantAdmin[],
    contacts: Contact[]
  ) => {
    if (!cliftState.currentTenant) {
      throw new Error("No current tenant");
    }

    const updatedTenant = {
      ...formValues,
      admins,
      contacts,
    };

    return updateTenant(tenant, tenant.parentTenant?.id ?? null, updatedTenant)
      .then(() => {
        dispatchCliftState({
          type: CliftReducerAction.FetchTenants,
        });
        closeSidePanel();
      })
      .catch((err) => {
        dispatchCliftState({
          type: CliftReducerAction.FetchTenants,
        });
        closeSidePanel();
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("tenant_edit_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
            reason: getLocalizedErrorReason(err),
          }),
        });
        toast.error(
          t("error") +
            t("tenant_edit_toast_error", {
              ns: "alerts",
              reason: getLocalizedErrorReason(err),
            })
        );
      });
  };

  return (
    <SideModal
      title={
        tenant.tenantType === TenantType.COUNTRY
          ? t("edit_market_tenant", { ns: "tenant" })
          : t("edit_tenant", { ns: "tenant" })
      }
      showPager
      pageCount={3}
      pageIndex={currentPageIndex}
      onClose={closeSidePanel}
    >
      <TenantForm
        pageIndex={currentPageIndex}
        initialValues={tenant}
        onSubmit={onUpdateTenant}
        onClose={closeSidePanel}
        onSwitchPage={setCurrentPageIndex}
        editingTenant={tenant}
        showSaveOnAllPages={true}
      />
    </SideModal>
  );
};
