import { useTranslation } from "react-i18next";
import { Panels } from "../context/clift-context/clift-context";
import { useCliftContext } from "../hooks/useCliftContext";
export function AlertsPanel() {
  const { t } = useTranslation();

  const { cliftState } = useCliftContext();
  if ((cliftState.visiblePanels & Panels.Alerts) == 0) return <></>;
  return (
    <div className="topPanel">
      {t("alerts", { ns: "alerts" })}
      <br />
      {cliftState.alerts.map((alert, i) => (
        <div key={i}>{alert}</div>
      ))}
    </div>
  );
}
