import { useState } from "react";
import { useCliftContext } from "../hooks/useCliftContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../assets/logo.svg";
import { ReactComponent as HomeIcon } from "../assets/Home.svg";
import { ReactComponent as ProfileIcon } from "../assets/Profile.svg";
import { ReactComponent as LiftIcon } from "../assets/Lift.svg";
import { TenantType } from "../models/tenant";

const Navbar = () => {
  const { cliftState, currentUser } = useCliftContext();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <nav className={collapsed ? "navbar-collapsed" : "navbar"}>
      <img alt="Stannah" id="logo" src={logo} />
      <button id="navbar-collapse" onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? ">" : "<"}
      </button>

      <menu>
        <li>
          <Link to="/">
            <div className="navbar-menu-item">
              <span>
                <HomeIcon className="navbar-icon" />
              </span>
              <span>{t("home")}</span>
            </div>
          </Link>
        </li>

        {cliftState.currentTenant &&
        currentUser.clientPrincipal?.userRoles?.includes("authenticated") ? (
          <>
            {(cliftState.currentTenant?.tenantType === TenantType.ROOT ||
              cliftState.currentTenant?.tenantType === TenantType.COUNTRY) && (
              <li>
                <Link to="/tenants">
                  <div className="navbar-menu-item">
                    <span>
                      <ProfileIcon className="navbar-icon" />
                    </span>
                    <span>
                      {cliftState.currentTenant?.tenantType ===
                        TenantType.ROOT && t("markets", { ns: "tenant" })}
                      {cliftState.currentTenant?.tenantType ===
                        TenantType.COUNTRY && t("tenants", { ns: "tenant" })}
                    </span>
                  </div>
                </Link>
              </li>
            )}
            {cliftState.currentTenant?.tenantType === TenantType.DEALER && (
              <li>
                <Link to="/customers">
                  <div className="navbar-menu-item">
                    <span>
                      <ProfileIcon className="navbar-icon" />
                    </span>
                    <span>{t("customers", { ns: "customer" })}</span>
                  </div>
                </Link>
              </li>
            )}
            <li>
              <Link to="/lifts">
                <div className="navbar-menu-item">
                  <span>
                    <LiftIcon className="navbar-icon" />
                  </span>
                  <span>{t("lifts", { ns: "lift" })}</span>
                </div>
              </Link>
            </li>
          </>
        ) : (
          <></>
        )}
      </menu>
    </nav>
  );
};

export default Navbar;
