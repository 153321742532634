import { useContext } from "react";
import { CliftActionContext } from "../context/clift-context/clift-context";
import { CliftContextInterface } from "../context/clift-context/clift-action-types";

export const useCliftContext = (): CliftContextInterface => {
  const context = useContext(CliftActionContext);
  if (!context) {
    throw new Error("useCliftContext must be used withtin a CliftProvider");
  }
  return context;
};
