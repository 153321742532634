import { ReactNode } from "react";
import "./NoItems.css";

export interface NoItemsProps {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  button?: ReactNode;
}

export const NoItems = ({ icon, title, subtitle, button }: NoItemsProps) => {
  return (
    <div className="no-items">
      <div className="no-items-icons">{icon}</div>
      <div className="no-items-title">{title}</div>
      <div className="no-items-subtitle">{subtitle}</div>
      <div className="no-items-button">{button}</div>
    </div>
  );
};
